<template>
  <div class="refusal-reason">
    <div class="wrapper">
      <div class="title font-md">
        <span>인증 거절 사유</span>
      </div>
      <div class="pad">
        <div class="desc font-sm">{{ modalParams.reason }}</div>
      </div>
    </div>
    <div class="action">
      <div class="row">
        <div class="col">
          <button class="btn btn-secondary btn-block font-sm" @click="$store.commit('closeModal', { name: component.name })">확인</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "@vue/composition-api";
import store from "@/scripts/store";
import Number from "@/components/Number";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "modalRefusalReason";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Number},
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "xs"});
    });

    const modalParams = store.getters.modalParams(component);

    return {component, modalParams};
  }
});
</script>

<style lang="scss" scoped>
.refusal-reason {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .pad {
      background: $colorBackground;
      padding: $px15;
      border: $px1 solid #eee;
      border-radius: $px4;
      margin-top: $px30;
    }
  }

  .action {
    button {
      padding: $px15;
      height: $px56;
      border-radius: 0;
    }
  }
}
</style>